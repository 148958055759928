import React, { useState } from 'react';
import './App.css';
import './pos.css';
import cafeLogo from '../images/cafe-logo.png'
import { Provider, useSelector } from 'react-redux'
import store from '../store/store';
import { PosItemSelection } from './PosItemSelection/PosItemSelectionView';
import { Footer } from './Footer/Footer';
import { BrowserRouter } from 'react-router-dom';
import { SERVER_HOST } from '../config';

function HomeComponent() {
  const [itemList, setItemList] = useState(store.getState().itemList);
  const [tapPopup, setTapPopup] = useState(store.getState().tapPopup);
  const [paymentList, setPaymentList] = useState(store.getState().paymentList);
  const [modifiers] = useState(store.getState().modifiers);
  const posItems = useSelector(state => state.posItems);

  const TAP_EXIT = "TAP_EXIT";
  const TAP_RELOAD = "TAP_RELOAD";
  const TAP_REQUEST = "TAP_REQUEST";
  const TAP_RESULT = "TAP_RESULT";
  const TAP_FAILED_RESULT = "TAP_FAILED_RESULT";
  const TAP_METHOD_SALE = "sale";
  const TAP_METHOD_REFUND = "refund";

  window.tapSaleResult = (status, amount, gratuity) => {
    setItemList({...itemList, posItemsActive: false});
    setTapPopup({...tapPopup, status: status, type: "sale", baseAmount: amount, gratuityAmount: gratuity});
  }

  function processTapSaleResult() {
    itemList.posItemsActive = true;
    itemList.isLocked = false;
    if (tapPopup.status === "success") {
      paymentList.push(
        { type: tapPopup.type, 
          baseAmount: tapPopup.baseAmount, 
          gratuityAmount: tapPopup.gratuityAmount, 
          date: new Date().toISOString(), 
          refundStatus: "AVAILABLE"
        })
      itemList.paid += tapPopup.baseAmount;
      itemList.total = itemList.subTotal - itemList.paid;
      if (itemList.total === 0) {
        itemList.items = [];
        itemList.subTotal = 0;
        itemList.paid = 0;
        itemList.currentItem = { index: -1 };
        itemList.currentModifiedItem = { index: -1 };
        itemList.posItemsVisible = true;
        itemList.modifyItemVisible = false;
      }
      setItemList({...itemList})
      setPaymentList([...paymentList])
      sendLogsToBackend(TAP_RESULT, TAP_METHOD_SALE, tapPopup.status, tapPopup.baseAmount + tapPopup.gratuityAmount)
    } else {
      sendLogsToBackend(TAP_RESULT, TAP_METHOD_SALE, tapPopup.status, null)
      console.error("Cannot process Tap sale as the result failed!")
    }
    setTapPopup({type: ""})
  }

  window.logTapExit = () => {
    sendLogsToBackend(TAP_EXIT, TAP_EXIT, "Logging request to exit POS into TaP", null)
  }

  window.logReloadRequest = () => {
    sendLogsToBackend(TAP_RELOAD, TAP_RELOAD, "Logging request to reload the TaP WebView", null)
  }

  window.logSaleRequest = (amount) => {
    sendLogsToBackend(TAP_REQUEST, TAP_METHOD_SALE, null, amount)
  }

  window.logRefundRequest = (amount) => {
    sendLogsToBackend(TAP_REQUEST, TAP_METHOD_REFUND, null, amount)
  }

  window.tapRefundResult = (status) => {
    const refundIndex = paymentList.findIndex(payment => payment.refundStatus === "PENDING");
    if (refundIndex !== -1) {
      const baseAmount = paymentList[refundIndex].baseAmount;
      const gratuityAmount = paymentList[refundIndex].gratuityAmount;
      setItemList({...itemList, posItemsActive: true});
      setTapPopup({...tapPopup, status: status, type: "refund", baseAmount: baseAmount, gratuityAmount: gratuityAmount, refundIndex: refundIndex});
      sendLogsToBackend(TAP_RESULT, TAP_METHOD_REFUND, status, baseAmount + gratuityAmount)
    } else {
      sendLogsToBackend(TAP_FAILED_RESULT, TAP_METHOD_REFUND, "refundable item not found", null)
      console.error("Cannot find a refundable item to process!")
    }
  }

  function processTapRefundResult() {
    if (tapPopup.status === "success") {
      const refund = { 
        type: "refund", 
        baseAmount: -(tapPopup.baseAmount + tapPopup.gratuityAmount), 
        gratuityAmount: 0, 
        date: new Date().toISOString()
      }
      paymentList.push(refund);
      paymentList[tapPopup.refundIndex].refundStatus = "REFUNDED";
      sendLogsToBackend(TAP_RESULT, TAP_METHOD_REFUND, tapPopup.status, refund.gratuityAmount);
    } else {
      paymentList[tapPopup.refundIndex].refundStatus = "AVAILABLE";
      sendLogsToBackend(TAP_RESULT, TAP_METHOD_REFUND, tapPopup.status, null);
    }
    setPaymentList([...paymentList]);
    setTapPopup({type: ""})
  }

  function sendLogsToBackend(stage, method, status, data) {
    fetch(`${SERVER_HOST}/log`, {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({ stage: stage, method: method, status: status, data: data })
    })
  }

  return (
    <>
      <div className="cafe-body floor-plan-body">

        <div id="errorContainer" className="container error-container">
          <div className="alert alert-danger fade in error-alert">
            <a href="/" className="close" data-dismiss="alert" aria-label="close" title="close">×</a>
            <ul id="errorText"></ul>
          </div>
        </div>

        <div id="mainContainer" className="container container__customize">
          <div className="row">
            <div className="col-xs-12 col-sm-12 visible-xs visible-sm">
              <div className="business-brand text-center full-screen-switcher">
                <div className="business-brand__client-logo">
                  <img src={cafeLogo} alt="Dojo" />
                </div>
                <div className="business-brand__client-name">
                  Frazer's Cafe
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="">
                <PosItemSelection 
                  posItems={posItems}
                  itemList={itemList} 
                  setItemList={setItemList}
                  cafeLogo={cafeLogo}
                  paymentList={paymentList}
                  setPaymentList={setPaymentList}
                  modifiers={modifiers}
                  tapPopup={tapPopup}
                  processTapSaleResult={processTapSaleResult}
                  processTapRefundResult={processTapRefundResult}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />  
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">
          <HomeComponent />
        </div>
      </Provider>
    </BrowserRouter>
  )
}

export default App;
