import React from 'react';
import dojoLogo from '../../images/logo.png'

function Footer() {
  return (
    <div className="footer">
      <img src={dojoLogo} height="70" alt="Dojo" />
    </div>
  );
}

export { Footer };