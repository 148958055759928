import React from 'react';
import CurrencyFormat from 'react-currency-format';

function PosReceiptItemDiv(props) {
  const { itemList, setItemList, index, name, price, quantity, category, posItemsActive, clearPreviouslySelectedItemsFromArray } = props;

  const currentItemIsSelected = itemList.items[index].selected;
  const currentItem = itemList.currentItem;

  function updateSelectedItem(e) {
    if (posItemsActive && (currentItem.index === -1 || itemList.items[index].selected === true)) {
      const updatedItems = itemList;
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      clearPreviouslySelectedItemsFromArray(itemList.items, index);
      updatedItems.items[selectedIndex].selected = !currentItemIsSelected;
      updatedItems.currentItem = {index: currentItemIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity, category: category};
      if (currentItemIsSelected) {
        updatedItems.modifyItemVisible = false;  
        updatedItems.currentModifiedItem = {index: -1};
        clearPreviouslySelectedItemsFromArray(itemList.items[index].modifiers);
      }
      setItemList({...itemList, 
        modifyItemVisible: updatedItems.modifyItemVisible, 
        currentItem: updatedItems.currentItem, 
        items: updatedItems.items,
        currentModifiedItem: updatedItems.currentModifiedItem
      });
    }
  }

  return (
    <div data-id={index}
      className={`receipt__selected-product__container
        ${posItemsActive && (currentItem.index === -1 || itemList.items[index].selected === true) ?
          "removableProduct" : "disabled-button"}
        ${currentItemIsSelected ? "selectedRemovableProduct" : ""}`} 
      onClick={(e) => updateSelectedItem(e)}
    >
      <div className="receipt__selected-product-name pull-left">{`${name.toUpperCase()} ${itemList.items[index].modifiers.length > 0 ? " (M)" : "" }`}</div>
      <div className="receipt__selected-product-price pull-right">
        <CurrencyFormat value={parseFloat(price / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
      </div>
      <div className="receipt__selected-product-price pull-right margin-left receipt-item-quantity">
        x {quantity}
      </div>
    </div>
  );
}

function PosReceiptModifiedItemDiv(props) {
  const { itemList, setItemList, parentIndex, index, name, quantity, price, posItemsActive, modifiers, clearPreviouslySelectedItemsFromArray } = props;

  const temp = itemList.items[parentIndex].modifiers[index]?.selected;
  const currentModifiedItemIsSelected = temp ? temp : false;
  const currentItem = itemList.currentItem;

  function updateSelectedModifiedItem(e) {
    if (posItemsActive && currentItem.index === parentIndex) {
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      const updatedItems = itemList;
      updatedItems.modifyItemVisible = true;
      updatedItems.modifiedItems = modifiers[currentItem.name];
      clearPreviouslySelectedItemsFromArray(itemList.items[parentIndex].modifiers, index);
      updatedItems.items[parentIndex].modifiers[selectedIndex].selected = !currentModifiedItemIsSelected;
      updatedItems.currentModifiedItem = {index: currentModifiedItemIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity};
      setItemList({...itemList, 
        modifyItemVisible: updatedItems.modifyItemVisible, 
        modifiedItems: updatedItems.modifiedItems, 
        items: updatedItems.items,
        currentModifiedItem: updatedItems.currentModifiedItem
      });
    }
  }

  return (
    <div data-id={index}
      className={`receipt__selected-product__container
        ${posItemsActive && currentItem.index === parentIndex ? "removableModifiableProduct" : "disabled-button"}
        ${currentModifiedItemIsSelected ? "selectedModifiableRemovableProduct" : ""}
        receipt__modified-product`} 
      onClick={(e) => updateSelectedModifiedItem(e)}
    >
      <div className="receipt__modified-prouct-name receipt__selected-product-name pull-left">{name.toUpperCase()}</div>
      <div className="receipt__selected-product-price pull-right">
        <CurrencyFormat value={parseFloat(price / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
      </div>
      <div className="receipt__selected-product-price pull-right margin-left receipt-item-quantity">
        x {quantity}
      </div>
    </div>
  );
}

export { PosReceiptItemDiv, PosReceiptModifiedItemDiv };