import React from 'react';
import { RECEIPT_TYPE_ITEM } from './PosReceipt/components/PosReceiptItem';

function PosItemRow(props) {

  const {itemList, setItemList, posItems } = props;
  
  const posItemRow = posItems.map((posItem) => 
    <PosItemTile 
      key={posItem.key}
      name={posItem.name} 
      price={posItem.price}
      category={posItem.category}
      id={posItem.key}
      itemList={itemList}
      setItemList={setItemList}
    />
  )

  return (
    <>
      <div className="row mr-minus-10">
        {posItemRow}
      </div>
    </>
  );
}

function PosItemTile(props) {

  const { id, name, price, category, itemList, setItemList } = props;
  const posItemsActive = itemList.posItemsActive;
  const currentItem = itemList.currentItem;

  function addPosReceiptItem() {
    if (posItemsActive && currentItem.index === -1) {
      const updatedItems = itemList;
  
      let existingItem = false;
      updatedItems.items.forEach((item, index) => {
        if (item.name === name && item.modifiers.length === 0) {
          updatedItems.items[index].quantity = updatedItems.items[index].quantity + 1;
          existingItem = true;
        }
      });
      if (!existingItem) {
        updatedItems.items.push({
          type: RECEIPT_TYPE_ITEM,
          name: name, 
          quantity: 1, 
          price: price, 
          category: category, 
          key: id, 
          modifiers: [],
        })
      }
      updatedItems.subTotal += price;
      updatedItems.total += price;
      setItemList({ ...itemList, items: updatedItems.items, subTotal: updatedItems.subTotal, total: updatedItems.total});
    }
  }

  return (
    <>
      <div className="col-xs-4 products__product"
          onClick={() => addPosReceiptItem()}>
        <div className={`product-click-container ${posItemsActive === false || currentItem.index !== -1 ? "disabled-button" : ""}`}>
          <div className={`products__product__image-container ${name}`}>
          </div>
          <div className="products__product__text text-center">
            {name.charAt(0).toUpperCase() + name.substring(1)}
          </div>
        </div>
      </div>
    </>
  );
}

export { PosItemRow };