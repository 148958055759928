import React from 'react';
import { RECEIPT_TYPE_ITEM, RECEIPT_TYPE_MODIFIER } from './PosReceipt/components/PosReceiptItem';

function PosButtons(props) {

  const { itemList, setItemList, modifiers, clearPreviouslySelectedItemsFromArray, tapPopup } = props;
  const currentItem = itemList.currentItem;
  const currentModifiedItem = itemList.currentModifiedItem;

  const modifiableItems = Object.keys(modifiers)
  // console.log("Current Item", currentItem, "Current Modified Item", currentModifiedItem)

  const isLocked = itemList.isLocked;
  const posItemsActive = itemList.posItemsActive;
  const noOfTableItems = itemList.items ? itemList.items.length : 0;
  const modifyItemVisible = itemList.modifyItemVisible;

  function bogofCheck() {
    const numberOfItem = itemList.items.reduce((sum, item) => {
      if (item.name === currentItem.name) {
        return sum + item.quantity;
      }
      return sum;
    }, 0);
    const numberOfItemBogofs = itemList.items.reduce((sum, item) => {
      if (item.modifiers.find(modifier => modifier.category === "bogof")) {
        return sum + 1;
      }
      return sum;
    }, 0);
    return numberOfItemBogofs <= Math.floor((numberOfItem - 1 )/ 2);
  }

  function removePosReceiptItem() {
    const updatedItems = itemList;

    // Check if we're removing a top level item OR a modified item
    if (currentModifiedItem.index === -1) {  
      const itemToDelete = updatedItems.items[currentItem.index];
      removeItem(updatedItems, itemToDelete, updatedItems.items, currentItem.index);
    } else {
      const itemToDelete = updatedItems.items[currentItem.index].modifiers[currentModifiedItem.index];
      removeItem(updatedItems, itemToDelete, updatedItems.items[currentItem.index].modifiers, currentModifiedItem.index);
    }
  }

  function modifiersCheck() {
    return modifiers[currentItem.name] ? modifiers[currentItem.name]?.find(mod => mod.category === "bogof") : true; 
  }

  function removeItem(updatedItems, itemToDelete, arrayToDeleteFrom, spliceIndex) {
    // Deleting exclusively where one of the items has a BOGOF modifier 
    let afterRemovingBogof = false;
    if (itemToDelete.type === RECEIPT_TYPE_ITEM && modifiersCheck()) { 
      const bogofCheckPasses = bogofCheck();

      if (!bogofCheckPasses) {
        let indexOfBogofMod = -1; 
        const indexOfBogofItem = updatedItems.items.findIndex(item => {
          indexOfBogofMod = item.modifiers.findIndex(mod => mod.category === "bogof");
          return indexOfBogofMod !== -1;
        });

        const item = updatedItems.items[indexOfBogofItem];
        const mod = item.modifiers[indexOfBogofMod];
    
        if (mod.quantity > 1) {
          mod.quantity = mod.quantity - 1;
        } else {
          item.modifiers.splice(indexOfBogofMod, 1);
          item.price = item.price - mod.price;
          removePriceFromTotals(updatedItems, mod.price);
          mergeDuplicateItems(updatedItems);
          afterRemovingBogof = true;
        }
      }
    }

    // Deleting where the item has more than 1 quantity 
    if (itemToDelete.quantity > 1) {
      itemToDelete.quantity -= 1;
      if (itemToDelete.type === RECEIPT_TYPE_MODIFIER) {
        updatedItems.items[currentItem.index].price -= itemToDelete.price;
      }
    } else {

      // Deleting where the item is a receipt item
      if(itemToDelete.type === RECEIPT_TYPE_ITEM) {
        if (itemToDelete.modifiers.length === 0) {
          updatedItems.currentModifiedItem = {index: -1};
        }
        updatedItems.modifyItemVisible = false;
        updatedItems.currentItem = {index: -1};
      } 

      arrayToDeleteFrom.splice(spliceIndex, 1);

      // Deleting where the item is a modifier that is being deleted   
      if (itemToDelete.type === RECEIPT_TYPE_MODIFIER) {
        updatedItems.items[currentItem.index].price -= itemToDelete.price;
        if (updatedItems.items[currentItem.index].modifiers.length === 0) {
          mergeDuplicateItems(updatedItems);
        }
        afterRemovingBogof = itemToDelete.category === "bogof" ? true : false;
        updatedItems.currentModifiedItem = {index: -1};
      }
    }

    if (afterRemovingBogof) {
      clearPreviouslySelectedItemsFromArray(updatedItems.items, -1)
      updatedItems.modifyItemVisible = false;
      updatedItems.currentItem = {index: -1};
    }
  
    removePriceFromTotals(updatedItems, itemToDelete.price);
    setItemList({ ...itemList, 
      currentItem: updatedItems.currentItem, 
      currentModifiedItem: updatedItems.currentModifiedItem,
      modifyItemVisible: updatedItems.modifyItemVisible,
      items: updatedItems.items, 
      subTotal: updatedItems.subTotal, 
      total: updatedItems.total
    });
  }

  function removePriceFromTotals(updatedItems, itemPrice) {
    updatedItems.subTotal -= itemPrice;
    updatedItems.total -= itemPrice;
  }

  function mergeDuplicateItems(updatedItems) {
    const duplicateItemIndex = updatedItems.items.findIndex((item, index) => {
      return item.name === currentItem.name && item.modifiers.length === 0 && index !== currentItem.index;
    })
    if (duplicateItemIndex !== -1) {
      const duplicateItem = getItemFromCurrentTable(duplicateItemIndex);
      currentItem.quantity += duplicateItem.quantity;
      itemList.items[currentItem.index].quantity += duplicateItem.quantity;
      updatedItems.items.splice(duplicateItemIndex, 1);
      currentItem.index = findItemIndexByNameAndQuantity(updatedItems.items, currentItem.name, currentItem.quantity)
    }
  }

  function findItemIndexByNameAndQuantity(array, name, quantity) {
    return array.findIndex(item => item.name === name && item.quantity === quantity);
  }

  function getItemFromCurrentTable(itemIndex){
    return itemList.items[itemIndex];
  }

  function toggleLock() {
    setItemList({...itemList, isLocked: !itemList.isLocked, posItemsActive: !itemList.posItemsActive})
  }

  function performSale() {
    setItemList({...itemList, isLocked: true})
    window['tapSale'](itemList.subTotal)
  }

  function setModifyItemVisible(value) {
    setItemList({...itemList, modifyItemVisible: value})
  }

  return (
    <>
      <div className="row">
        <div className="buttons col-xs-12 col-sm-12 col-md-12 pull-right">
          <div className="row mr-minus-10">
            <div className="col-xs-4 buttons__button__container">
              {posItemsActive && isLocked === false &&
                <button
                  className={`buttons__button button__cancel-transaction ${noOfTableItems === 0 ? "disabled-button" : ""}`}
                  disabled={noOfTableItems === 0 ? true : false}
                  type="button"
                  onClick={() => toggleLock()}>
                  Hold
                </button>
              }
              {!posItemsActive && isLocked === true &&
                <button
                  className={`buttons__button button__cancel-transaction ${tapPopup.title || itemList.paid > 0 ? "disabled-button" : ""}`}
                  disabled={tapPopup.title || itemList.paid > 0 ? true : false}
                  type="button"
                  onClick={() => toggleLock()}>
                  Release
                </button>
              }
            </div>
            <div className="col-xs-4 buttons__button__container">
              {!modifyItemVisible && isLocked === false &&
                <button
                  className={`buttons__button button__modify ${currentItem.index !== -1 && 
                    modifiableItems.includes(currentItem.name) ? "" : "disabled-button"}`} disabled={currentItem.index === -1 ? true : false}
                  type="button"
                  onClick={() => {
                    if (modifiableItems.includes(currentItem.name)) {
                      itemList.modifiedItems = modifiers[currentItem.name];
                      setModifyItemVisible(true)
                    }
                  }}>
                  Modify Item
                </button>
              }
              {modifyItemVisible && isLocked === false &&
                <button
                  className={`buttons__button button__modify`}
                  type="button"
                  onClick={() => {
                    clearPreviouslySelectedItemsFromArray(itemList.items[currentItem.index].modifiers, -1)
                    setModifyItemVisible(false)
                  }}>
                  Back
                </button>
              }
              {isLocked === true &&
                <button
                  className={`buttons__button button__modify ${tapPopup.title ? "disabled-button" : ""}`}
                  disabled={tapPopup.title ? true : false}
                  type="button"
                  onClick={() => performSale()}>
                  Sale
                </button>
              }
            </div>
            <div className="col-xs-4 buttons__button__container">
              {isLocked === false &&
                <button
                  className={`buttons__button button__remove ${currentItem.index === -1 ? "disabled-button" : ""}`} disabled={currentItem.index === -1 ? true : false}
                  type="button"
                  onClick={() => removePosReceiptItem()}>
                  Remove Item
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { PosButtons };