import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { PosReceiptItem } from './components/PosReceiptItem';

function PosReceipt(props) {

  const { itemList, modifiers, setItemList, clearPreviouslySelectedItemsFromArray } = props;

  const posItemsActive = itemList.posItemsActive;

  const posItemList = itemList.items.map((item, key) => 
    <PosReceiptItem
      key={key}
      index={key}
      name={item.name} 
      quantity={item.quantity}
      price={item.price}
      category={item.category}
      modifiers={modifiers}
      itemList={itemList}
      setItemList={setItemList}
      posItemsActive={posItemsActive}
      clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
    /> 
  )

  return (
    <>
      <div className="col-xs-12 col-sm-5 col-md-5">
        <div className="row  ">
          <div className="receipt-before-zigzag">

          </div>
          <div id="receipt" className='receipt'>
            { posItemList }
          </div>
          <div className="receipt-after-zigzag">

          </div>
          <div className="payments">
            <div className="payments-row-container">
              <span className="payments__text">Sub-Total:</span>
              <span id="balance" className="payments__amount">
                <CurrencyFormat value={parseFloat(itemList.subTotal / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
              </span>
            </div>
            <div className="payments-row-container">
              <span className="payments__text">Paid:</span>
              <span id="paymentsTotal" className="payments__amount">
                <CurrencyFormat value={parseFloat(itemList.paid / 100)} prefix={'-£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
              </span>
            </div>
            <div className="payments-row-container">
              <span className="payments__text">Total:</span>
              <span id="leftToPay" className="payments__amount">
                <CurrencyFormat value={parseFloat(itemList.total / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { PosReceipt };