import React from 'react';
import { PosReceiptItemDiv, PosReceiptModifiedItemDiv } from './PosReceiptItemDiv';

const RECEIPT_TYPE_ITEM = "RECEIPT_TYPE_ITEM";
const RECEIPT_TYPE_MODIFIER = "RECEIPT_TYPE_MODIFIER";

function PosReceiptItem(props) {

  const { index, name, quantity, price, category, modifiers, posItemsActive, 
    setItemList, itemList, clearPreviouslySelectedItemsFromArray } = props;

  const currentModifiers = itemList.items[index].modifiers;

  const currentModifiersList = currentModifiers.map((modifier, modifierPos) => {
    return (
      <PosReceiptModifiedItemDiv 
        itemList={itemList}
        setItemList={setItemList}
        parentIndex={index}
        key={modifierPos}
        index={modifierPos}
        name={modifier.name}
        quantity={modifier.quantity}
        price={modifier.price}
        posItemsActive={posItemsActive} 
        modifiers={modifiers}
        clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
      />
    )
  })

  return (
    <>
      <PosReceiptItemDiv 
        itemList={itemList}
        setItemList={setItemList}
        index={index}
        name={name}
        price={price}
        quantity={quantity}
        category={category}
        posItemsActive={posItemsActive} 
        clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
      />
      {currentModifiersList}
    </>
  );
}

export { PosReceiptItem, RECEIPT_TYPE_ITEM, RECEIPT_TYPE_MODIFIER };