import React from 'react';
import CurrencyFormat from 'react-currency-format';

function CompletedTransactions(props) {
  const { paymentList, setPaymentList, tapPopup } = props;

  const txs = paymentList.map((tx, index) => {
    return (
      <tr key={index}>
        <td>{tx.type}</td>
        <td><CurrencyFormat value={parseFloat(tx.baseAmount / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></td>
        <td><CurrencyFormat value={parseFloat(tx.gratuityAmount / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></td>
        <td>{tx.date.substring(11, 16)}</td>
        <td style={{"paddingLeft": "0"}}>
          {tx.type === "sale" &&
            <button 
              disabled={!tapPopup.title && tx.refundStatus === "AVAILABLE" ? false : true}
              className="button__refund"
              onClick={() => captureRefund(index, tx.baseAmount, tx.gratuityAmount)}>
              Refund
            </button>
          }
        </td>
      </tr>
    );
  });

  function captureRefund(index, baseAmount, gratuity) {
    paymentList[index].refundStatus = "PENDING";
    setPaymentList([...paymentList]);
    window['tapRefund'](baseAmount, gratuity)
  }

  return (
    <>
      <div className="closed-tables-transactions-container" style={{"minHeight": "590px"}}>
        <div className='border'>
          <table id="closed-tables-trasactions" className="transactions__transactions-table table text-center">
            <thead>
              <tr>
                <th>Type</th>
                <th>Amount</th>
                <th>Gratuity</th>
                <th>Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="transactions__transactions-table-data">
              {txs}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export { CompletedTransactions }