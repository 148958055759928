import React from 'react';

import { PosItemRow } from './components/PosItemTile';
import { PosReceipt } from './components/PosReceipt/PosReceiptView';
import { CompletedTransactions } from './components/CompletedTransactions';
import { PosButtons } from './components/PosButtons';
import { ModifiedItemRow } from './components/ModifiedItemTile';
import CurrencyFormat from 'react-currency-format';

function PosItemSelection(props) {

  const {posItems, itemList, setItemList, cafeLogo, paymentList, setPaymentList, modifiers, tapPopup, processTapSaleResult, processTapRefundResult } = props;

  const modifiedItems = itemList.modifiedItems;

  function clearPreviouslySelectedItemsFromArray(array, index) {
    array.forEach((modifiedItem, i) => {
      if (i !== index) {
        modifiedItem.selected = false;
      }
    })
    setItemList(itemList);
  }

  const posItemList = posItems.map((posItem, index) => {
    return (index % 3 === 0) ? <PosItemRow
      key={posItem.key}
      itemList={itemList}
      setItemList={setItemList}
      posItems={posItems.slice(index, index+3)}
    /> : "";
  })

  const modifiedItemsList = modifiedItems.map((modifiedItem, index) => {
    return (index % 3 === 0) ? <ModifiedItemRow
      key={modifiedItem.key}
      itemList={itemList}
      setItemList={setItemList}
      modifiedItems={modifiedItems.slice(index, index+3)}
    /> : "";
  })

  return (
    <>
        <div className="table-details-mode__container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-3 visible-md visible-lg">
            <div className="business-brand pat-business-brand-desktop text-center">
              <div className="business-brand__client-logo">
                <img src={cafeLogo} alt="Dojo" />
              </div>
              <div className="business-brand__client-name">
                Frazer's Cafe
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9">
            <div className="row section-name-row">
              <div className="col-xs-4 buttons__button__container">
                <button
                  className={`buttons__button button__menu`}
                  type="button"
                  onClick={() => setItemList({...itemList, posItemsVisible: true})}>
                  Item Selection
                </button>
              </div>
              <div className="col-xs-4 buttons__button__container">
                <button
                  className={`buttons__button button__menu`}
                  type="button"
                  onClick={() => setItemList({...itemList, posItemsVisible: false})}>
                  Transactions
                </button>
              </div>
              <div className="col-xs-2 buttons__button__container">
                <button
                  className={`buttons__button button__menu`}
                  type="button"
                  onClick={() => window['tapReload']()}>
                  Reload
                </button>
              </div>
              <div className="col-xs-2 buttons__button__container">
                <button
                  style={{"borderRight":"none"}}
                  className={`buttons__button button__menu`}
                  type="button"
                  onClick={() => window['posExit']()}>
                  Exit
                </button>
              </div>
              {/* <div className="mode-close-button pull-right pointer" onClick={() => window['posExit']()}>
                X
              </div> */}
            </div>
            {tapPopup.type?.length > 0 &&
              <div id="tapPopupContainer">
                <div id="tapPopup">
                  <div id='tapPopupTitle'>{tapPopup.type === "sale" && "Tap Sale Result"}{tapPopup.type === "refund" && "Tap Refund Result"}</div>
                    <div id='tapPopupDescription'>
                      {tapPopup.status === "success" &&<span>Successfully processed</span>}
                      {tapPopup.status !== "success" &&<span>Failed to process</span>}
                      <span> a {tapPopup.type} for </span>
                      <CurrencyFormat value={parseFloat(tapPopup.baseAmount / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/> 
                      <span> with a gratuity of </span>
                      <CurrencyFormat value={parseFloat(tapPopup.gratuityAmount / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
                    </div>
                    {tapPopup.type === "sale" && <button id='tapPopupButton' onClick={() => processTapSaleResult()}>OK</button>}
                    {tapPopup.type === "refund" && <button id='tapPopupButton' onClick={() => processTapRefundResult()}>OK</button>}
                </div>
              </div>
            }
            <div className="f5f5f5-bg pd10">
              {itemList.posItemsVisible === true &&
                <div className="row receipt-and-products-container">
                  <PosReceipt 
                    itemList={itemList} 
                    setItemList={setItemList}
                    modifiers={modifiers}
                    clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
                  />   
                  <div className="col-xs-12 col-sm-7 col-md-7 custom-products-row">
                    <div className="products">
                      <PosButtons 
                        itemList={itemList}
                        setItemList={setItemList}
                        modifiers={modifiers}
                        clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
                        tapPopup={tapPopup}
                      />
                      {!itemList.modifyItemVisible === true && posItemList}
                      {itemList.modifyItemVisible === true && modifiedItemsList}
                    </div>   
                  </div>
                </div>
              }
              {itemList.posItemsVisible === false &&
                <div className="row receipt-and-products-container">
                  <CompletedTransactions
                    paymentList={paymentList}
                    setPaymentList={setPaymentList}
                    tapPopup={tapPopup}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
  </>
  );
}

export { PosItemSelection };